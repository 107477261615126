import { rtkApi } from "src/shared/api/rtkApi";
import { apiAdmin } from "src/shared/config";

export const inspectionDriversQuery = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getInspectionById: builder.query({
      query: (id) => ({
        url: `${apiAdmin}/inspection/${id}`,
        method: "GET",
      }),
    }),
    chgInspectionStatus: builder.query({
      query: (inspectionInfo) => ({
        url: `${apiAdmin}/v2/inspection/${inspectionInfo.id}/status`,
        method: "PUT",
        body: inspectionInfo,
      }),
    }),
  }),
});

export const { useGetInspectionByIdQuery, useLazyChgInspectionStatusQuery } = inspectionDriversQuery;
