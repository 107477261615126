import { getQueryParam } from "src/shared/lib/url/queryParamHelpers";

const parseQueryParamRange = (name) => {
  const param = getQueryParam(name);

  if (param.length > 0) {
    const [from, to] = param[0].split(" - ").map(Number);
    return [from ?? null, to ?? null];
  }

  return [null, null];
};

const generateInitialFormValues = (initialSchema) => {
  return Object.entries(initialSchema).reduce((initialValues, [key, value]) => {
    if (value.min !== undefined && value.max !== undefined) {
      const [from, to] = parseQueryParamRange(key);
      initialValues[key] = { from, to };
    }

    if (value.values) {
      initialValues[key] = getQueryParam(key, []);
    }

    return initialValues;
  }, {});
};

export default generateInitialFormValues;
