import React, { memo } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import { AppBar, Container, Toolbar, IconButton, Typography, Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { t } from "i18next";

import { ReactComponent as CarmaLogo } from "src/assets/CarmaLogo.svg";

const MenuNavLink = styled(RouterLink)(({ theme }) => ({
  paddingBottom: "6px",
  borderBottom: "2px solid transparent",
  "&.active": {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
}));

const PartnerHeader = () => {
  const location = useLocation();

  const menuItems = [
    { label: t("drivers"), path: "drivers" },
    // { label: "Автомобили", path: "cars" },
    // { label: "Заказы", path: "orders" },
    // { label: "Отчёты", path: "reports" },
    // { label: "Карта", path: "map" },
    // { label: "Настройка парка", path: "park-settings" },
    // { label: "Документы", path: "documents" },
    { label: t("invoice"), path: "partner-invoice" },
  ];

  const isNavLinkActive = (path) => {
    return location.pathname.includes(path);
  };

  return (
    <AppBar color="inherit" position="static" sx={{ boxShadow: "0px 1px 4px 0px #0000001A" }}>
      <Container maxWidth="xl">
        <Toolbar>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <RouterLink to="/partner">
                <CarmaLogo height={80} width={100} />
              </RouterLink>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {menuItems.map(({ label, path }) => (
                  <MenuNavLink
                    className={isNavLinkActive(path) ? "active" : ""}
                    component={RouterLink}
                    key={path}
                    sx={{ mr: 7, padding: "0 9px 6px 9px" }}
                    to={path}
                  >
                    <Typography color={isNavLinkActive(path) ? "secondary" : "text.secondary"} variant="font14_light">
                      {label}
                    </Typography>
                  </MenuNavLink>
                ))}
              </Box>
            </Grid>
            <Grid item>
              <IconButton aria-label="profile">
                <PersonIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default memo(PartnerHeader);
