const Link = () => {
  return {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
  };
};

export default Link;
