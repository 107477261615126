import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";

import CircularLoader from "src/shared/components/loaders/circle-loader";
import TableComponent from "src/shared/components/table";
import useDropdownList from "src/shared/hooks/useDropdownList";
import usePaginate from "src/shared/hooks/usePaginate";

import ActionsDropdown from "./components/ActionsDropdown";
import DocumentsModal from "./components/DocumentModal";
import DriverTableRow from "./components/DriverTableRow";
import OptionButtonsModal from "./components/OptionButtonsModal";
import { SkeletonTable } from "./skeleton";
import {
  useGetSearchFiltersQuery,
  useLazyGetDocumentTitlesByIdQuery,
  useLazyGetDriversListQuery,
} from "./store/queries/drivers-query";
import PartnerPage from "../../components/PartnerPage";

const getSelectDriverButtons = (t, handleNavigation, handleOpenDocuments, currentDriver) => [
  { text: t("Profile"), onClick: handleNavigation(currentDriver.id) },
  { text: t("Documents"), onClick: handleOpenDocuments },
  { text: t("payroll_module"), onClick: handleNavigation(`salary/${currentDriver.id}`) },
  { text: t("invoices"), onClick: handleNavigation(`invoice/${currentDriver.id}`) },
  // { text: t("technical_inspection"), onClick: handleNavigation(`inspection/${currentDriver.lastInspectionId}`) },
];

const getHeadColumns = (t) => [
  { title: t("full_name"), styledTableCellProps: { align: "left" } },
  { title: t("rating"), styledTableCellProps: { align: "center" } },
  { title: t("online_hours"), styledTableCellProps: { align: "center" } },
  { title: t("last_online"), styledTableCellProps: { align: "center" } },
  { title: t("car_count"), styledTableCellProps: { align: "center" } },
  { title: t("orders"), styledTableCellProps: { align: "center" } },
  { title: t("balance"), styledTableCellProps: { align: "center" } },
  { title: t("commission_carma_and_flot"), styledTableCellProps: { align: "center" } },
  { title: t("groups"), styledTableCellProps: { align: "center" } },
  { title: t("activationStatus"), styledTableCellProps: { align: "center" } },
  { title: t("registration_date"), styledTableCellProps: { align: "center" } },
  { title: t("car"), styledTableCellProps: { align: "right" } },
];

const getDropdownOptions = (t, setOptionsModalOpen, dropdownFunctions) => [
  {
    text: t("view"),
    icon: <PersonIcon />,
    onClick: () => {
      setOptionsModalOpen(true);
      dropdownFunctions.onCloseDropdown();
    },
  },
];

const DriversPage = () => {
  const [currentDriver, setCurrentDriver] = useState({});
  const [isDocumentModalOpen, setDocumentModalOpen] = useState(false);
  const [isOptionsModalOpen, setOptionsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { paginateState, paginateFunctions } = usePaginate({ initialPage: 1, initialTake: 5 });
  const { dropdownState, dropdownFunctions } = useDropdownList();

  const { data: filtersData, isLoading: isFiltersLoading } = useGetSearchFiltersQuery();
  const [fetchDriversList, { data: driversData, isLoading: isDriversLoading }] = useLazyGetDriversListQuery();
  const [fetchDocumentTitlesById, { data: documentTitles = [] }] = useLazyGetDocumentTitlesByIdQuery();

  const handleApiCall = useCallback(
    async (additionalParams) => {
      const finalParams = { paging: { skip: paginateState.skip, take: paginateState.take }, ...additionalParams };
      await fetchDriversList(finalParams);
    },
    [fetchDriversList, paginateState.skip, paginateState.take],
  );

  if (isFiltersLoading) {
    return <CircularLoader color="secondary" />;
  }

  const handleOpenDocuments = async () => {
    await fetchDocumentTitlesById(currentDriver.id);
    setOptionsModalOpen(false);
    setDocumentModalOpen(true);
  };

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleOpenDropdown = (event, driver) => {
    dropdownFunctions.onOpenDropdown(event, dropdownOptions.view, "view");
    setCurrentDriver(driver);
  };

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTabContent = () => {
    if (!driversData || isDriversLoading) {
      return <SkeletonTable />;
    }

    switch (selectedTab) {
      case 0:
        return (
          <TableComponent
            count={paginateFunctions.calculateTotalPages(driversData?.total)}
            currentPage={paginateState.currentPage}
            handlePageChange={paginateFunctions.onPageChange}
            headColumns={headColumns}
          >
            {driversData.data.map((driver) => (
              <DriverTableRow
                currentDriver={currentDriver}
                driver={driver}
                dropdownAnchor={dropdownState.dropdownAnchor}
                key={driver.id}
                onClick={handleOpenDropdown}
              />
            ))}
          </TableComponent>
        );
      case 1:
        return <div>Регистрации</div>;
      default:
        return null;
    }
  };

  const selectDriverButtons = getSelectDriverButtons(t, handleNavigation, handleOpenDocuments, currentDriver);
  const headColumns = getHeadColumns(t);
  const dropdownOptions = getDropdownOptions(t, setOptionsModalOpen, dropdownFunctions);
  const breadcrumbs = [{ url: "/partner", text: t("main_page") }, { text: t("drivers") }];

  const filtersConfig = {
    findTotalTitle: t("fount_count_driver", { count: driversData?.count || 0 }),
    onTakeChange: paginateFunctions.onTakeChange,
    initialFilterData: filtersData.filters,
    onCallApi: handleApiCall,
  };
  const titleBarConfig = {
    buttonText: t("add_driver"),
    onButtonClick: handleNavigation("create-driver"),
    title: t("drivers"),
  };
  const tabsConfig = {
    tabs: [
      { label: t("fleet_drivers_count", { count: driversData?.total || 0 }) },
      { label: t("registrations_count", { count: 0 }), disabled: true },
    ],
    selectedTab,
    onTabChange: handleTabChange,
  };

  return (
    <PartnerPage breadcrumbs={breadcrumbs} filtersConfig={filtersConfig} tabsConfig={tabsConfig} titleBarConfig={titleBarConfig}>
      <OptionButtonsModal isOpen={isOptionsModalOpen} onClose={() => setOptionsModalOpen(false)} options={selectDriverButtons} />
      <DocumentsModal
        isOpen={isDocumentModalOpen}
        onClose={() => setDocumentModalOpen(false)}
        options={documentTitles}
        title={t("view_photo")}
      />
      {renderTabContent()}
      <ActionsDropdown
        actions={dropdownOptions}
        dropdownAnchor={dropdownState.dropdownAnchor}
        onCloseDropdown={dropdownFunctions.onCloseDropdown}
        options={dropdownState.options}
      />
    </PartnerPage>
  );
};

export default DriversPage;
