import { Link as RouterLink } from "react-router-dom";

import { Breadcrumbs, Link, Box, Typography } from "@mui/material";

const PathBreadcrumbs = ({ sx, links = [] }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" separator="/" sx={sx}>
      {links.map(({ text, url }) => (
        <Box component="span" key={text}>
          {url ? (
            url !== "/partner" ? (
              <Link component={RouterLink} to={url}>
                <Typography color="text.primary" variant="font16_medium">
                  {text}
                </Typography>
              </Link>
            ) : (
              <Typography color="text.primary" variant="font16_medium">
                {text}
              </Typography>
            )
          ) : (
            <Typography color="text.secondary" variant="font16_medium">
              {text}
            </Typography>
          )}
        </Box>
      ))}
    </Breadcrumbs>
  );
};

export default PathBreadcrumbs;

//* Do not delete, return when the page is ready [/partner] *//
// const PathBreadcrumbs = ({ sx, links = [] }) => {
//   const renderLink = (link) => {
//     return (
//       <Box component="span" key={link.text}>
//         {link.url ? (
//           <Link component={RouterLink} to={link.url}>
//             <Typography color="text.primary" variant="font16_medium">
//               {link.text}
//             </Typography>
//           </Link>
//         ) : (
//           <Typography color="text.secondary" variant="font16_medium">
//             {link.text}
//           </Typography>
//         )}
//       </Box>
//     );
//   };

//   return (
//     <Breadcrumbs aria-label="breadcrumb" separator="/" sx={sx}>
//       {links.map(renderLink)}
//     </Breadcrumbs>
//   );
// };
