import { ImageListItem, ImageList, ImageListItemBar } from "@mui/material";

import CustomModal from "src/shared/components/modal";

const DocumentsModal = (props) => {
  const { onClose, isOpen, options, title } = props;

  return (
    <CustomModal onClose={onClose} open={isOpen} title={title}>
      {options?.documents && (
        <ImageList cols={2}>
          {options.documents.map((item) => (
            <ImageListItem key={item.description}>
              <img alt={item.description} loading="lazy" src={item.url} />
              <ImageListItemBar title={item.description} />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </CustomModal>
  );
};

export default DocumentsModal;
