import React from "react";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";

import { createRoot } from "react-dom/client";

import { StoreProvider } from "src/shared/store/StoreProvider/ui/StoreProvider";

import App from "./App";
import i18n from "./i18n/config";
import "./styles/root.scss";
import ThemeComponent from "./theme";

const root = createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
    <StoreProvider>
      <Router>
        <ThemeComponent>
          <App />
        </ThemeComponent>
      </Router>
    </StoreProvider>
  </I18nextProvider>,
);
