import React from "react";

// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import SettingsIcon from "@mui/icons-material/Settings";
// import SortIcon from "@mui/icons-material/Sort";
import TuneIcon from "@mui/icons-material/Tune";
import { Stack, Button, Typography } from "@mui/material";

import DropdownList from "src/shared/components/DropdownList";
import useDropdownList from "src/shared/hooks/useDropdownList";

const DataControlPanel = (props) => {
  const { showFilters, setShowFilters, onTakeChange } = props;

  const { dropdownState, dropdownFunctions } = useDropdownList();

  const dropdownOptions = {
    paginationItem: [
      { text: "5", onClick: () => onTakeChange(5), isDefault: true },
      { text: "40", onClick: () => onTakeChange(40) },
      { text: "60", onClick: () => onTakeChange(60) },
      { text: "100", onClick: () => onTakeChange(100) },
    ],
  };

  const toggleFilters = () => {
    setShowFilters((prevFilterState) => !prevFilterState);
  };

  const handleSettings = (event) => {
    dropdownFunctions.onOpenDropdown(event, dropdownOptions.paginationItem, "paginationItem");
  };

  const getSelectedOptionText = (optionType, defaultValue) => {
    const selectedOption = dropdownState.selectedOptions[optionType];
    return selectedOption ? selectedOption.text : defaultValue;
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ mb: 10 }}>
      <Stack direction="row" spacing={7}>
        <Button
          color={showFilters ? "active" : "primary"}
          endIcon={<TuneIcon color="icon" />}
          onClick={toggleFilters}
          variant="outlined"
        >
          <Typography variant="font14_light">Фильтр</Typography>
        </Button>
        {/* <Button color="primary" endIcon={<SettingsIcon color="icon" />} variant="outlined">
          <Typography variant="font14_light">Настройки</Typography>
        </Button>
        <Button color="primary" endIcon={<SortIcon color="icon" />} variant="outlined">
          <Typography variant="font14_light">Сортировка</Typography>
        </Button> */}
      </Stack>

      <Stack direction="row" spacing={7}>
        {/* <Button color="primary" endIcon={<KeyboardArrowDownIcon color="icon" />} variant="outlined">
          <Typography variant="font14_light">Сегодня</Typography>
        </Button>
        <Button
          color="primary"
          endIcon={<CalendarMonthIcon color="icon" />}
          onClick={() => {}}
          variant="outlined"
        >
          <Typography variant="font14_light">Фев 11 — Фев 11</Typography>
        </Button>
        <Button
          color="primary"
          endIcon={<FileDownloadOutlinedIcon color="icon" />}
          onClick={() => {}}
          variant="outlined"
        >
          <Typography variant="font14_light">Скачать</Typography>
        </Button> */}
        <Button color="primary" onClick={handleSettings} sx={{ ml: 3 }} variant="clear">
          <Typography variant="font14_light">
            Показывать по{" "}
            <Typography color="active.main" component="span" variant="font14_medium">
              {getSelectedOptionText("paginationItem", "5")}
            </Typography>
          </Typography>
        </Button>
      </Stack>

      <DropdownList
        anchorEl={dropdownState.dropdownAnchor}
        onClose={dropdownFunctions.onCloseDropdown}
        onSelect={dropdownFunctions.onSelectOption}
        options={dropdownState.options}
        selectedOption={dropdownState.selectedOptions[dropdownState?.type]?.text}
      />
    </Stack>
  );
};

export default DataControlPanel;
