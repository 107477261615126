const buildQueryString = (params) => {
  const searchParams = new URLSearchParams(window.location.search);

  Object.entries(params).forEach(([name, value]) => {
    if (!value || !value?.length) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, Array.isArray(value) ? value.join(",") : value);
    }
  });

  return `?${searchParams.toString()}`;
};

export const addQueryParams = (params) => {
  window.history.pushState(null, "", buildQueryString(params));
};

export const getQueryParam = (paramName, defaultValue = "") => {
  const searchParams = new URLSearchParams(window.location.search);
  const paramValue = searchParams.get(paramName);

  if (!paramValue) {
    return defaultValue;
  }

  return paramValue.split(",").map((value) => {
    const parsedValue = parseInt(value, 10);
    return isNaN(parsedValue) || value.includes("-") ? value : parsedValue;
  });
};

export const removeQueryParam = (paramName, label = null) => {
  const paramValues = getQueryParam(paramName, []);
  const updatedValues = label ? paramValues.filter((v) => v !== label) : [];

  const params = { [paramName]: updatedValues };
  window.history.pushState(null, "", buildQueryString(params));
};

export const removeAllQueryParams = () => {
  window.history.pushState(null, "", window.location.pathname);
};
