import React from "react";

import {
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import ContentWrap from "src/shared/components/content-wrap";

import styles from "./styles.module.scss";

const InvoiceTablePagination = (props) => {
  const { tabs, rows, data, setCurrentPage, buttonText, totalPages, itemsPerPage, emptyDataText } = props;
  return (
    <div className={styles.invoiceTable}>
      {!data || data.length === 0 ? (
        <p className={styles.invoicesNotFound}>{emptyDataText}</p>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  {tabs.map((item, index) => (
                    <TableCell align="center" key={`${item}_${index}`}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={`${row.date}_${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      <p> {row.date}</p>
                      <p>{row.time}</p>
                    </TableCell>
                    <TableCell align="center">
                      <Button align="center" href={row.url} variant="outlined">
                        {buttonText}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            className={styles.driverInvoicePagination}
            count={totalPages}
            onChange={(_, page) => setCurrentPage({ skip: page * itemsPerPage, page })}
            shape="rounded"
            variant="outlined"
          />
        </>
      )}
    </div>
  );
};

export default InvoiceTablePagination;
