import React, { useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import { t } from "i18next";

import InvoiceTablePagination from "src/features/invoice-table-pagination";

import CircularLoader from "src/shared/components/loaders/circle-loader";
import PathBreadcrumbs from "src/shared/components/path-breadcrumbs";

import { useGetInvoiceDataQuery } from "./store/queries/invoice-query";

const DriverInvoice = () => {
  const { driverId } = useParams();
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState({ skip: 0, page: 1 });
  const { data, error, isLoading } = useGetInvoiceDataQuery({ driverId, skip: currentPage.skip, take: 10 });

  if (isLoading) {
    return <CircularLoader />;
  }

  const totalPages = Math.ceil(data.total / itemsPerPage);

  const sortedData =
    data?.data?.sort((a, b) => dayjs(b.sentAt).valueOf() - dayjs(a.sentAt).valueOf()) || null;

  const tabs = [t("date"), t("invoices")];
  const rows = sortedData.map((item) => ({
    date: dayjs(item.sentAt).format("DD MMM YYYY"),
    time: dayjs(item.sentAt).format("HH:mm:ss"),
    url: item.url,
  }));

  const links = [
    { url: "/partner", text: t("main_page") },
    { url: "/partner/drivers", text: t("drivers") },
    { text: t("invoice_driver") },
  ];

  return (
    <>
      <PathBreadcrumbs links={links} sx={{ margin: "28px 0" }} />
      <InvoiceTablePagination
        buttonText={t("open")}
        data={sortedData}
        emptyDataText={t("invoices_not_found")}
        itemsPerPage={itemsPerPage}
        rows={rows}
        setCurrentPage={setCurrentPage}
        tabs={tabs}
        totalPages={totalPages}
      />
    </>
  );
};

export default DriverInvoice;
