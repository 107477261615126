import RemoveIcon from "@mui/icons-material/Remove";
import { TableRow, TableCell, Typography, Avatar, Box } from "@mui/material";

import { formatDayMonthYear } from "src/shared/services/dateFormatters";

import getStatusIcon from "../../helpers/getDriverStatusIcon";

const renderTableCell = (value, align = "left") => (
  <TableCell align={align}>
    <Typography color="text.main" variant="font12_light">
      {value}
    </Typography>
  </TableCell>
);

const DriverTableRow = (props) => {
  const { driver, currentDriver, dropdownAnchor, onClick } = props;

  const isActive = currentDriver.id === driver.id && dropdownAnchor;

  return (
    <TableRow hover>
      <TableCell>
        <Box
          onClick={(event) => onClick(event, driver)}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <Avatar
            alt="User Photo"
            src={driver.photoUrl || ""}
            sx={{
              width: 56,
              height: 56,
              transition: "border-color 0.3s ease",
              border: isActive ? "2px solid" : "2px solid",
              borderColor: isActive ? "secondary.main" : "grey.300",
            }}
          >
            {!driver.photoUrl && `${driver.firstName.charAt(0)}${driver.lastName.charAt(0)}`}
          </Avatar>

          <Box sx={{ ml: 3 }}>
            <Typography color="text.main" component="p" variant="font14_light">
              {driver.firstName}
            </Typography>
            <Typography color="text.main" component="p" variant="font14_light">
              {driver.lastName}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      {renderTableCell(driver.rating, "center")}
      {renderTableCell(<RemoveIcon fontSize="small" />, "center")}
      {renderTableCell(<RemoveIcon fontSize="small" />, "center")}
      {renderTableCell(<RemoveIcon fontSize="small" />, "center")}
      {renderTableCell(<RemoveIcon fontSize="small" />, "center")}
      {renderTableCell(driver.balance, "center")}
      {renderTableCell(<RemoveIcon fontSize="small" />, "center")}
      {renderTableCell(<RemoveIcon fontSize="small" />, "center")}
      {renderTableCell(getStatusIcon(driver), "center")}
      {renderTableCell(formatDayMonthYear(driver.createdAt), "center")}
      {renderTableCell(<RemoveIcon fontSize="small" />, "right")}
    </TableRow>
  );
};

export default DriverTableRow;
