import { createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance, axiosInstanceWithMainApiToken } from "src/shared/api/axios.config";
import { apiAdmin, apiDocuments, apiLogin } from "src/shared/config";

export const loginPartner = createAsyncThunk("partner/loginPartner", async (data, thunkAPI) => {
  try {
    const response = await axiosInstance.post(apiLogin, data);
    return response.data;
  } catch (error) {
    if (!error.response.data.errors) {
      return thunkAPI.rejectWithValue({ general: error.response.data.message });
    }

    return thunkAPI.rejectWithValue(error.response.data.errors);
  }
});

// Remove
export const fetchPartnerDriversList = createAsyncThunk("partner/fetchPartnerDriversList", async (payload, thunkAPI) => {
  try {
    const { data } = await axiosInstanceWithMainApiToken.post(`${apiAdmin}/drivers/partner-list`, payload);
    return data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Remove
export const fetchDocumentTitlesById = createAsyncThunk("driver/fetchDocumentTitles", async (id, thunkAPI) => {
  try {
    const { data } = await axiosInstanceWithMainApiToken.get(`${apiDocuments}/${id}`);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchDriverInfo = createAsyncThunk("driver/fetchDriverInfo", async (id, thunkAPI) => {
  try {
    const { data } = await axiosInstanceWithMainApiToken.get(`${apiAdmin}/v2/drivers/${id}`);
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
