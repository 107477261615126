import {
  Table,
  Box,
  TableBody,
  TableRow,
  Pagination,
  TableContainer,
  TableHead,
  Typography,
  TableCell,
} from "@mui/material";

const TableComponent = (props) => {
  const { headColumns, count, currentPage, handlePageChange, children } = props;

  return (
    <>
      <TableContainer>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              {headColumns.map((column) => (
                <TableCell key={column.title} sx={{ lineHeight: "17px" }} {...column.styledTableCellProps}>
                  <Typography color="text.secondary" variant="font12_light">
                    {column.title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      <Box alignItems="center" display="flex" justifyContent="center" mt={10} pb={10}>
        <Pagination
          count={count}
          onChange={handlePageChange}
          page={currentPage}
          shape="rounded"
          size="large"
        />
      </Box>
    </>
  );
};

export default TableComponent;
