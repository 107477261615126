import { memo } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";

const TitleBarWithButton = ({ title, buttonText, onButtonClick }) => {
  return (
    <Box alignItems="center" color="text.main" display="flex" justifyContent="space-between">
      <Typography variant="h1">{title}</Typography>
      {buttonText && (
        <Button color="secondary" endIcon={<AddIcon sx={{ mr: 5 }} />} onClick={onButtonClick} size="large" variant="contained">
          <Typography color="text.light" variant="font16_medium">
            {buttonText}
          </Typography>
        </Button>
      )}
    </Box>
  );
};

export default memo(TitleBarWithButton);
