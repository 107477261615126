import { initReactI18next } from "react-i18next";

import dayjs from "dayjs";
import i18n from "i18next";
import Cookies from "js-cookie";
import "dayjs/locale/de";
import "dayjs/locale/ru";
import "dayjs/locale/sk";
import "dayjs/locale/uk";

const langCookie = Cookies.get("language_name");
const supportedLanguages = ["EN", "SK", "UA"];
const lng = langCookie && supportedLanguages.includes(langCookie) ? langCookie : "SK";

dayjs.locale(lng === "UA" ? "uk" : lng.toLowerCase());

i18n.use(initReactI18next).init({
  fallbackLng: lng,
  lng: lng,
  resources: {
    EN: {
      translations: require("./locales/en/translations.json"),
    },
    SK: {
      translations: require("./locales/sk/translations.json"),
    },
    UA: {
      translations: require("./locales/ua/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
});

i18n.languages = ["EN", "SK", "UA"];

export default i18n;
