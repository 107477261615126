import { Provider } from "react-redux";

import { createReduxStore } from "../index";

export const StoreProvider = (props) => {
  const { children } = props;

  const store = createReduxStore();

  return <Provider store={store}>{children}</Provider>;
};
