const useAuth = () => {
  const mainApiAccessToken = localStorage.getItem("mainApiAccessToken");

  if (mainApiAccessToken) {
    return { isAuth: true };
  }

  return { isAuth: false };
};

export default useAuth;
