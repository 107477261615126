import { rtkApi } from "src/shared/api/rtkApi";
import { apiDocuments, apiDriver } from "src/shared/config";

export const driversQuery = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getSearchFilters: builder.query({
      query: () => ({
        url: `${apiDriver}/search/filters`,
        method: "GET",
      }),
    }),
    getDriversList: builder.query({
      query: (payload) => ({
        url: `${apiDriver}/search`,
        method: "POST",
        body: payload,
      }),
    }),
    getDocumentTitlesById: builder.query({
      query: (id) => ({
        url: `${apiDocuments}/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetSearchFiltersQuery, useLazyGetDriversListQuery, useLazyGetDocumentTitlesByIdQuery } = driversQuery;
