import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";

import CheckboxGroupsField from "../components/CheckboxGroupsField";
import SliderFormField from "../components/SliderFormField";

const useFilterFields = (initialData, initialValues) => {
  const { t } = useTranslation();

  const renderSliderField = (key) => (
    <Grid item key={key}>
      <SliderFormField key={key} label={t(key)} max={initialData[key].max} min={initialData[key].min} name={key} />
    </Grid>
  );

  const renderCheckboxField = (key) => (
    <Grid item key={key}>
      <CheckboxGroupsField label={t(key)} name={key} values={initialData[key].values} />
    </Grid>
  );

  const filterFields = useMemo(() => {
    return Object.entries(initialValues).reduce(
      (accumulator, [key, value]) => {
        if (typeof value === "object" && ("from" in value || "to" in value)) {
          accumulator.sliderFields.push(renderSliderField(key));
        } else if (Array.isArray(value)) {
          accumulator.checkboxFields.push(renderCheckboxField(key));
        }
        return accumulator;
      },
      { sliderFields: [], checkboxFields: [] },
    );
  }, [initialValues]);

  return filterFields;
};

export default useFilterFields;
