import { Box, Tabs, Tab } from "@mui/material";

const TabsComponent = (props) => {
  const { onTabChange, selectedTab, tabs } = props;

  return (
    <Box sx={{ mt: 5 }}>
      <Tabs aria-label="simple tabs example" onChange={onTabChange} value={selectedTab}>
        {tabs.map((tab, index) => (
          <Tab disabled={tab.disabled} key={index} label={tab.label} sx={{ mr: 10 }} />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabsComponent;
