import { rtkApi } from "src/shared/api/rtkApi";
import { apiAdmin } from "src/shared/config";

export const profileDriversQuery = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getDriverInfo: builder.query({
      query: (id) => ({
        url: `${apiAdmin}/v2/drivers/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetDriverInfoQuery } = profileDriversQuery;
