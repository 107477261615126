import { configureStore } from "@reduxjs/toolkit";

import { driverReducer } from "src/pages/create-driver/model/driverSlice";
import { partnerReducer } from "src/pages/partner/model/partnerSlice";

import { rtkApi } from "src/shared/api/rtkApi";

export function createReduxStore() {
  const rootReducer = {
    partner: partnerReducer,
    driver: driverReducer,

    // rtk query reducers
    [rtkApi.reducerPath]: rtkApi.reducer,
  };

  return configureStore({
    reducer: rootReducer,

    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkApi.middleware),
  });
}
