import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { t } from "i18next";

import CircularLoader from "src/shared/components/loaders/circle-loader";
import PathBreadcrumbs from "src/shared/components/path-breadcrumbs";

import { useGetDriverInfoQuery } from "./store/queries/profile-query";
import { ReactComponent as TechPassportIcon } from "../../assets/TechPassportIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";

import "./styles.scss";

const Profile = () => {
  const navigate = useNavigate();
  const { driverId } = useParams();
  const { data, isLoading, error } = useGetDriverInfoQuery(driverId);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <CircularLoader />;
  }

  if (error) {
    handleGoBack();
    // some login for error
  }

  const inputFields = [
    {
      items: [
        { label: t("name"), value: data.firstName },
        { label: t("last_name"), value: data.lastName },
      ],
    },
    {
      items: [
        { label: t("country"), value: data.country },
        { label: t("call_sign"), value: data.callSign },
      ],
    },
    {
      items: [
        { label: t("phone_number"), value: data.phone },
        { label: t("email"), value: data.email },
      ],
    },
  ];

  const links = [
    { url: "/partner", text: t("main_page") },
    { url: "/partner/drivers", text: t("drivers") },
    { text: `${data.firstName} ${data.lastName}` },
  ];

  return (
    <>
      <PathBreadcrumbs links={links} sx={{ margin: "28px 0" }} />
      <div className="driver_main_block">
        <p className="drivers_data_title">{t("drivers_data")}</p>
        <div className="drivers_info_block">
          <div className="drivers_photo">
            {data.photo ? (
              <img alt="" src={data.photo} />
            ) : (
              <div className="drivers_photo_none">
                <div className="drivers_circle_none_photo">
                  <UserIcon />
                </div>
              </div>
            )}
          </div>
          <div className="drivers_info_grounds">
            {inputFields.map((group, index) => (
              <div className="drivers_info_ground" key={index}>
                {group.items.map((item, itemIndex) => (
                  <div className="driver_input pointer_none" key={itemIndex}>
                    <p>
                      <span>*</span> {item.label}
                    </p>
                    <input readOnly value={item.value} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <p className="drivers_data_title block_with_padding_top">{t("drivers_license")}</p>
        <div className="driver_license_block block_with_padding_bottom">
          <div className="driver_license_single_item">
            {data.driverLicense ? (
              <img alt="" src={data.driverLicense} />
            ) : (
              <div className="driver_license_block_none">
                <div className="driver_license_block_none_icon">
                  <TechPassportIcon />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
