import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { Button, Container } from "@mui/material";
import { t } from "i18next";

import { ReactComponent as AvatarIcon } from "src/assets/AvatarIcon.svg";

import CircularLoader from "src/shared/components/loaders/circle-loader";
import PathBreadcrumbs from "src/shared/components/path-breadcrumbs";
import { formatDayMonthYearWidthDot } from "src/shared/services/dateFormatters";

import { useGetInspectionByIdQuery, useLazyChgInspectionStatusQuery } from "./store/queries/inspection-query";

import "./styles.scss";

const Inspection = () => {
  const [status, setStatus] = useState(null);

  const navigate = useNavigate();
  const { inspectionId } = useParams();

  const { data, isLoading, error } = useGetInspectionByIdQuery(inspectionId);
  const [chgInspectionStatus] = useLazyChgInspectionStatusQuery();

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setStatus(data?.data?.status);
  }, [data?.data?.status]);

  if (isLoading) {
    return <CircularLoader />;
  }

  if (error) {
    return handleGoBack();
  }

  const statusText =
    {
      0: t("new"),
      1: t("approved"),
      2: t("rejected"),
    }[status] || "";

  const handleStatusChange = async (newStatus) => {
    const inspectionInfo = {
      id: inspectionId,
      status: newStatus,
    };

    await chgInspectionStatus(inspectionInfo);
    setStatus(newStatus);
  };

  const formattedCreatedAt = formatDayMonthYearWidthDot(data.data.createdAt);
  const links = [
    { url: "/partner", text: t("main_page") },
    { url: "/partner/drivers", text: t("drivers") },
    { text: data.data.carBrandAndModel },
  ];
  return (
    <Container maxWidth="xl">
      <PathBreadcrumbs links={links} sx={{ margin: "28px 0" }} />

      <div className="inspections_main_block">
        <div className="main_driver_info">
          <div className="driver_details_info">
            <AvatarIcon />
            <div className="main_info">
              <p className="main_text">{t("driver")}</p>
              <p className="secondary_text">{data.data.driverName}</p>
            </div>
          </div>
        </div>
        <div className="inspections_info_block">
          <div className="inspections_info_grounds">
            {[
              [
                { label: t("car_mark"), value: data.data.brand },
                { label: t("car_model"), value: data.data.model },
                { label: t("car_class"), value: data.data.class, withSelectCountry: true },
              ],
              [
                { label: t("application_date"), value: formattedCreatedAt },
                { label: t("country"), value: data.data.country },
                { label: t("status"), value: statusText },
              ],
            ].map((group, index) => (
              <div className="inspections_info_ground" key={index}>
                {group.map((item, idx) => (
                  <div className="inspection_input disabled_inputs" key={idx}>
                    <p>
                      <span>*</span> {item.label}
                    </p>
                    <div className={item.withSelectCountry ? "select-country" : ""}>
                      <input readOnly value={item.value} />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <p className="inspections_data_title block_with_padding_top">
          {t("technical_inspection")} <span className="data_title_span">{formattedCreatedAt}</span>
        </p>
        <div className="inspection_car_block">
          {data.data.files.map((file) => (
            <div className="inspection_car_single_item" key={file.id}>
              <img alt="" src={file.file} />
            </div>
          ))}
        </div>
        <div className="info-buttons">
          {status === 1 && (
            <Button color="error" onClick={() => handleStatusChange(2)} variant="contained">
              {t("reject")}
            </Button>
          )}

          {(status === 0 || status === 2) && (
            <>
              <div className="border-buttons" />
              <Button color="success" onClick={() => handleStatusChange(1)} variant="contained">
                {t("approve")}
              </Button>
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Inspection;
