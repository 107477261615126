import { Box, Typography, Button } from "@mui/material";

import CustomModal from "src/shared/components/modal";

const OptionButtonsModal = (props) => {
  const { onClose, isOpen, options } = props;

  return (
    <CustomModal onClose={onClose} open={isOpen}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingBottom: "10px",
        }}
      >
        {options.map((driverButton, index) => (
          <Button
            key={`${driverButton.text}_${index}`}
            onClick={driverButton.onClick}
            sx={{
              backgroundColor: "secondary.main",
              width: "100%",
              "&:hover": { backgroundColor: "secondary.main" },
            }}
            variant="filled"
          >
            <Typography color="primary.light" variant="font16_medium">
              {driverButton.text}
            </Typography>
          </Button>
        ))}
      </Box>
    </CustomModal>
  );
};

export default OptionButtonsModal;
