const Slider = (theme) => {
  return {
    MuiSlider: {
      styleOverrides: {
        root: {
          width: "90%",
          marginLeft: 9,
          padding: "24px 0",
        },
        rail: {
          height: 2,
          borderRadius: 4,
          backgroundColor: theme.palette.commonColors.grey[100],
        },
        track: {
          height: 2,
          backgroundColor: theme.palette.secondary.light,
        },
        thumb: {
          height: 20,
          width: 20,
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.commonColors.grey[200]}`,
          boxShadow: "none",
        },
      },
    },
  };
};

export default Slider;
