const Pagination = (theme) => ({
  MuiPagination: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
        border: `1px solid ${theme.palette.primary.main}`,
        margin: 0,
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        margin: 0,
        borderRight: `1px solid ${theme.palette.primary.main}`,
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "0",

        "&.MuiPaginationItem-page": {
          color: theme.palette.text.secondary,
        },

        "&.Mui-selected": {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.text.light,
          border: "none",
          borderRadius: "0",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "0",
          },
        },
      },
    },
  },
  MuiPaginationText: {
    styleOverrides: {
      root: {
        color: "#fff",
      },
    },
  },
});

export default Pagination;
