import { Fragment, useEffect, useState } from "react";

import { Button, Box, Slider, Typography, FormControl, Divider } from "@mui/material";
import { useField } from "formik";

import { addQueryParams } from "src/shared/lib/url/queryParamHelpers";

const SliderFormField = (props) => {
  const { label, name, min, max } = props;

  const [field, meta, helpers] = useField(name);

  const { from, to } = field.value || {};

  const [range, setRange] = useState([from || min, to || max]);
  const [disabled, setDisabled] = useState(true);

  const handleConfirmRange = () => {
    const rangeString = range.join(" - ");

    setDisabled(true);
    addQueryParams({ [name]: rangeString });
    helpers.setValue({ from: range[0], to: range[1] });
  };

  const handleSliderChange = (_, newValue) => {
    setRange(newValue);

    if (field.value.from === newValue[0] && field.value.to === newValue[1]) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  };

  useEffect(() => {
    setRange([from || min, to || max]);
  }, [from, max, min, to]);

  return (
    <Box>
      <Typography component="p" gutterBottom variant="font14_light">
        {label}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl name={name} sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {range.map((value, index) => (
              <Fragment key={index}>
                <Button color="secondary" disabled size="small" variant="outlined">
                  <Typography variant="font14_light">{value}</Typography>
                </Button>
                {index === 0 && (
                  <Divider
                    orientation="vertical"
                    sx={{
                      width: "8px",
                      height: "2px",
                      margin: "0 5px",
                      backgroundColor: "primary.main",
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Box>
        </FormControl>
        <Button
          color="secondary"
          disabled={disabled}
          onClick={handleConfirmRange}
          size="small"
          sx={{ ml: "20px" }}
          variant="outlined"
        >
          <Typography variant="font14_light">OK</Typography>
        </Button>
      </Box>
      <Slider max={max} min={min} onChange={handleSliderChange} value={range} />
    </Box>
  );
};

export default SliderFormField;
