import { useState } from "react";

const useDropdownList = () => {
  const [dropdownAnchor, setDropdownAnchor] = useState(null);
  const [dropdownData, setDropdownData] = useState({ options: [], type: null });
  const [selectedOptions, setSelectedOptions] = useState({});

  const resetDropdown = () => setDropdownData({ options: [], type: null });

  const onOpenDropdown = (event, menuOptions, type) => {
    setDropdownAnchor(event.currentTarget);
    setDropdownData({ options: menuOptions, type });
  };

  const onCloseDropdown = () => {
    setDropdownAnchor(null);
    setTimeout(resetDropdown, 200);
  };

  const onSelectOption = (option) => {
    option?.onClick?.();

    setSelectedOptions((prevSelected) => ({
      ...prevSelected,
      [dropdownData.type]: option,
    }));

    onCloseDropdown();
  };

  return {
    dropdownState: { dropdownAnchor, options: dropdownData.options, type: dropdownData.type, selectedOptions },
    dropdownFunctions: { onOpenDropdown, onCloseDropdown, onSelectOption },
  };
};

export default useDropdownList;
