import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import { Chip, Box, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { removeQueryParam } from "src/shared/lib/url/queryParamHelpers";

import getTitleForTags from "../../helpers/getTitleForTags";

const FilterTags = (props) => {
  const { initialFilterData, setShowFilterTags, findTotalTitle, resetForm } = props;

  const formik = useFormikContext();
  const { t } = useTranslation();

  const isAllOptionsSelected = (optionKey) => {
    return Object.keys(initialFilterData[optionKey].values).length === formik.values[optionKey].length;
  };

  const handleDelete = (key, label) => {
    setShowFilterTags(true);
    formik.setValues((prevValue) => {
      if (Array.isArray(prevValue[key])) {
        const updatedArray = prevValue[key].filter((item) => item !== label);
        return { ...prevValue, [key]: updatedArray.length > 0 ? updatedArray : [] };
      }

      if (typeof prevValue[key] === "object" && "from" in prevValue[key] && "to" in prevValue[key]) {
        return { ...prevValue, [key]: { from: null, to: null } };
      }

      return prevValue;
    });

    removeQueryParam(key, label);
  };

  const renderChip = (key, label) => {
    const currentTitle = getTitleForTags(key);

    return (
      <Chip
        color="primary"
        deleteIcon={<CloseIcon />}
        key={label}
        label={
          <Typography color="text.main" variant="font14_light">
            {t(label)} {currentTitle}
          </Typography>
        }
        onDelete={() => handleDelete(key, label)}
        sx={{ mr: 2 }}
        variant="filled"
      />
    );
  };

  const renderValue = (key, value) => {
    if (!value || value.from === null || value.to === null) {
      return;
    }

    if (typeof value === "object" && "from" in value && "to" in value) {
      return renderChip(key, `${value.from} - ${value.to}`);
    }

    if (Array.isArray(value)) {
      return value.map((item) => renderChip(key, item)).filter(() => !isAllOptionsSelected(key));
    }
  };

  const chipsToRender = useMemo(
    () =>
      Object.entries(formik.values)
        .flatMap(([key, value]) => renderValue(key, value))
        .filter(Boolean),
    [formik.values],
  );

  useEffect(() => {
    setShowFilterTags(chipsToRender.length > 0);
  }, [chipsToRender.length]);

  return (
    <Box display="flex" sx={{ m: "15px 0 50px 0" }}>
      <Box>
        <Typography sx={{ mr: 15, whiteSpace: "nowrap" }} variant="font14_light">
          {findTotalTitle}
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap" gap={4}>
        <Chip
          color="primary"
          deleteIcon={<CloseIcon />}
          label={
            <Typography color="text.main" variant="font14_light">
              {t("reset")}
            </Typography>
          }
          onDelete={resetForm}
          sx={{ mr: 2 }}
          variant="filled"
        />
        {chipsToRender}
      </Box>
    </Box>
  );
};

export default FilterTags;
