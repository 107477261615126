import { useState } from "react";

const usePaginate = ({ initialPage, initialTake }) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [take, setTake] = useState(initialTake);

  const onPageChange = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const onTakeChange = (newTake) => {
    setTake(newTake);
    setCurrentPage(initialPage);
  };

  const calculateTotalPages = (totalItems) => Math.ceil(totalItems / take) || 0;
  const skip = (currentPage - 1) * initialTake;

  return {
    paginateState: { currentPage, take, skip },
    paginateFunctions: { onPageChange, onTakeChange, calculateTotalPages },
  };
};

export default usePaginate;
