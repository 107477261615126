const Tabs = (theme) => {
  return {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 2,
          bottom: 6,
          backgroundColor: theme.palette.active.primary,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.secondary,
          padding: 0,
          minWidth: 0,
          margin: "0 20px 0 0",
          fontSize: "16px",

          "&.Mui-selected": {
            color: "currentColor",
            fontWeight: theme.typography.fontWeightRegular,
          },
        },
      },
    },
  };
};

export default Tabs;
