const Chip = () => {
  return {
    MuiChip: {
      styleOverrides: {
        filled: {
          borderRadius: 5,
        },
        deleteIcon: {
          width: 13,
          height: 13,
        },
      },
    },
  };
};

export default Chip;
