import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import breakpoints from "./breakpoints";
import overrides from "./overrides";
import palette from "./palette";
import spacing from "./spacing";
import typography from "./typography";

const ThemeComponent = (props) => {
  const { children, mode = "light" } = props;

  let theme = createTheme({
    palette: palette(mode),
    typography: {
      fontFamily: "Inter, sans-serif",
    },
    breakpoints: breakpoints(),
    ...spacing,
  });

  theme = createTheme(theme, {
    components: { ...overrides(theme) },
    typography: { ...typography(theme) },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeComponent;
