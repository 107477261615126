import { Menu, MenuItem, Box, Typography } from "@mui/material";

const DropdownList = (props) => {
  const { options, anchorEl, onClose, onSelect, selectedOption, children, sx } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      disableScrollLock
      elevation={0}
      keepMounted
      onClose={onClose}
      open={Boolean(anchorEl)}
      sx={sx}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {children ? (
        children
      ) : (
        <Box style={{ display: "flex" }}>
          {options.map((option, idx) => {
            return (
              <MenuItem key={idx} onClick={() => onSelect(option)} sx={{ p: "12px 14px 12px 14px" }}>
                <Typography
                  color={selectedOption === option.text || option.isDefault ? "text.main" : "text.secondary"}
                  variant="font14_medium"
                >
                  {option.text}
                </Typography>
              </MenuItem>
            );
          })}
        </Box>
      )}
    </Menu>
  );
};

export default DropdownList;
