const Divider = () => {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderRightWidth: "inherit",
        },
      },
    },
  };
};

export default Divider;
