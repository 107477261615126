const List = () => {
  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiMenu-paper": {
            boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemText: {
      styleOverrides: {},
    },
    MuiListSubheader: {},
  };
};

export default List;
