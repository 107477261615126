import React from "react";
import { Outlet } from "react-router-dom";

import { Container } from "@mui/material";

import PartnerHeader from "src/pages/partner/components/PartnerHeader";

import useAuth from "src/shared/hooks/useAuth";

const PartnerLayout = () => {
  const { isAuth } = useAuth();

  return (
    <>
      {isAuth && <PartnerHeader />}

      <Container maxWidth="xl">
        <Outlet />
      </Container>

      {/* {isAuth && <PartnerFooter />} */}
    </>
  );
};

export default PartnerLayout;
