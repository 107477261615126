import React from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const CustomModal = ({ open, title, onClose, children, sx }) => {
  return (
    <Dialog onClose={onClose} open={open} sx={sx}>
      <DialogTitle align="center">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
