import dayjs from "dayjs";
import React, { useMemo } from "react";
import { salaryAmountCategories } from "../constants";
import { dateToCorrectFormat } from "../helpers/dayToCorrectFormat";

const createAmountArray = ({ array, newArray }) => {
  array.forEach((operation) => {
    const existingItemIndex = newArray.findIndex((chart) => {
      return dateToCorrectFormat(chart.date) === dateToCorrectFormat(operation.date);
    });
    //if item not exist - add to array
    if (existingItemIndex === -1) {
      return newArray.push({ date: operation.date, amount: operation.amount, fee: 0 });
    }
    return (newArray[existingItemIndex].amount += operation.amount);
  });
};

const useChartsItems = ({ salaryData, currentPayment }) => {
  const currentSalaryItems = useMemo(() => {
    const { earnAmountByCategories, expensesAmountByCategories } = salaryData;

    let chartsItems = [];

    const getDateAmount = () => {
      switch (currentPayment.value) {
        case salaryAmountCategories.cash:
          createAmountArray({ array: earnAmountByCategories.cash.operations, newArray: chartsItems });
          return;
        case salaryAmountCategories.inApp:
          createAmountArray({ array: earnAmountByCategories.inApp.operations, newArray: chartsItems });
          return;

        // chosen all income money
        default:
          for (const key in earnAmountByCategories) {
            if (earnAmountByCategories[key].operations) {
              createAmountArray({ array: earnAmountByCategories[key].operations, newArray: chartsItems });
            }
          }
          return;
      }
    };

    getDateAmount();

    // add fee to all dates
    expensesAmountByCategories.fee.operations.forEach((operation) => {
      const existingItemIndex = chartsItems.findIndex((chart) => chart.date === operation.date);
      if (existingItemIndex !== -1) {
        // if filed not exists - create him
        if (chartsItems[existingItemIndex].fee) {
          chartsItems[existingItemIndex].fee += operation.amount;
        } else {
          chartsItems[existingItemIndex].fee = operation.amount;
        }
      }
    });

    const sortedByDateCharts = chartsItems.sort((a, b) => {
      const dateA = dayjs(a.date);
      const dateB = dayjs(b.date);

      if (dateA.isBefore(dateB)) {
        return -1;
      }
      if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    });

    return sortedByDateCharts;
  }, [currentPayment, salaryData]);

  return currentSalaryItems;
};

export default useChartsItems;
