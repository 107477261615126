import { useCallback, useEffect, useMemo } from "react";

import { useFormik } from "formik";

import { removeAllQueryParams } from "src/shared/lib/url/queryParamHelpers";

import generateInitialFormValues from "../helpers/generateInitialFormValues";
import transformValuesToServer from "../helpers/transformValuesToServer";

const useFiltersForm = (initialSchema, onCallApi) => {
  const formik = useFormik({
    initialValues: generateInitialFormValues(initialSchema || {}),
  });

  const resetForm = useCallback(() => {
    removeAllQueryParams();
    formik.resetForm({ values: generateInitialFormValues(initialSchema || {}) });
  }, []);

  const transformedValues = useMemo(() => transformValuesToServer(formik.values, initialSchema), [formik.values, initialSchema]);

  useEffect(() => {
    onCallApi(transformedValues);
  }, [onCallApi, transformedValues]);

  return { formik, resetForm };
};

export default useFiltersForm;
