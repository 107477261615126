import MuiButton from "./button";
import MuiChip from "./chip";
import MuiDivider from "./divider";
import MuiInput from "./input";
import MuiLink from "./link";
import MuiList from "./list";
import MuiPagination from "./pagination";
import MuiSlider from "./slider";
import MuiTable from "./table";
import MuiTabs from "./tabs";
import MuiTypography from "./typography";

const Overrides = (theme) => {
  const button = MuiButton(theme);
  const input = MuiInput(theme);
  const slider = MuiSlider(theme);
  const divider = MuiDivider(theme);
  const typography = MuiTypography(theme);
  const chip = MuiChip(theme);
  const link = MuiLink(theme);
  const pagination = MuiPagination(theme);
  const table = MuiTable(theme);
  const list = MuiList(theme);
  const tabs = MuiTabs(theme);

  return Object.assign(button, input, slider, divider, typography, chip, link, pagination, table, list, tabs);
};

export default Overrides;
