import { rtkApi } from "src/shared/api/rtkApi";
import { mainBackendApi } from "src/shared/config";

export const partnerInvoiceQuery = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerInvoiceData: builder.query({
      query: ({ take, skip }) => ({
        url: `${mainBackendApi}/partner/v1/partners/invoices?skip=${skip}&take=${take}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetPartnerInvoiceDataQuery } = partnerInvoiceQuery;