import { memo, useState } from "react";

import { Box, Grid, Collapse } from "@mui/material";
import { Form, FormikProvider } from "formik";

import DataControlPanel from "./components/DataControlPanel";
import FilterTags from "./components/FilterTags";
import useFilterFields from "./hooks/useFilterFields";
import useFiltersForm from "./hooks/useFiltersForm";

const FiltersPanel = (props) => {
  const { initialFilterData, onTakeChange, findTotalTitle, onCallApi } = props;

  const [showFilters, setShowFilters] = useState(false);
  const [showFilterTags, setShowFilterTags] = useState(false);

  const { formik, resetForm } = useFiltersForm(initialFilterData, onCallApi);
  const filterFields = useFilterFields(initialFilterData, formik.initialValues);

  return (
    <Box sx={{ mt: 5 }}>
      <FormikProvider value={formik}>
        {/* <SearchField sx={{ mb: 6 }} /> */}

        <Form>
          <DataControlPanel onTakeChange={onTakeChange} setShowFilters={setShowFilters} showFilters={showFilters} />
          <Collapse in={showFilters} timeout="auto">
            <Grid container justifyContent="flex-start" spacing={20}>
              {filterFields.sliderFields}
              {filterFields.checkboxFields}
            </Grid>
            <Collapse in={showFilterTags}>
              <FilterTags
                findTotalTitle={findTotalTitle}
                initialFilterData={initialFilterData}
                resetForm={resetForm}
                setShowFilterTags={setShowFilterTags}
              />
            </Collapse>
          </Collapse>
        </Form>
      </FormikProvider>
    </Box>
  );
};

export default memo(FiltersPanel);
