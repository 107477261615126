import React from "react";

import FiltersPanel from "src/features/filters-panel";

import PathBreadcrumbs from "src/shared/components/path-breadcrumbs";

import TabsComponent from "./components/Tabs";
import TitleBarWithButton from "./components/TitleBarWithButton";

const PartnerPage = (props) => {
  const { children, breadcrumbs, titleBarConfig, filtersConfig, tabsConfig } = props;

  return (
    <>
      <PathBreadcrumbs links={breadcrumbs} sx={{ margin: "28px 0" }} />
      <TitleBarWithButton {...titleBarConfig} sx={{ margin: "30px 0 40px 0" }} />
      {tabsConfig && <TabsComponent {...tabsConfig} />}
      <FiltersPanel {...filtersConfig} />

      {children}
    </>
  );
};

export default PartnerPage;
