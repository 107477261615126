import { createSlice } from "@reduxjs/toolkit";

import { fetchDocumentTitlesById, fetchDriverInfo, fetchPartnerDriversList, loginPartner } from "./partnerAsyncActions";

const initialState = {
  tokens: {
    accessToken: localStorage.getItem("accessToken"),
    mainApiAccessToken: localStorage.getItem("mainApiAccessToken"),
  },
  isLoading: false,
  errors: {},
  driversList: [],
  documentPhotos: [],
  driverInfo: [],
  inspectionInfo: [],
};

const handleTokens = (state, action) => {
  const { accessToken, mainApiAccessToken } = action.payload;

  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("mainApiAccessToken", mainApiAccessToken);

  state.tokens.accessToken = accessToken;
  state.tokens.mainApiAccessToken = mainApiAccessToken;
};

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    logout: (state) => {
      state.tokens = {};
      localStorage.removeItem("accessToken");
      localStorage.removeItem("mainApiAccessToken");
    },
  },
  extraReducers: (builder) => {
    builder
      // Auth partner
      .addCase(loginPartner.pending, (state) => {
        state.isLoading = true;
        state.errors = {};
      })
      .addCase(loginPartner.fulfilled, (state, action) => {
        state.isLoading = false;
        handleTokens(state, action);
        state.errors = {};
      })
      .addCase(loginPartner.rejected, (state, action) => {
        state.errors = action.payload;
        state.isLoading = false;
      })

      // Fetch partner drivers list
      .addCase(fetchPartnerDriversList.pending, (state) => {
        state.isLoading = true;
        state.errors = {};
      })
      .addCase(fetchPartnerDriversList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.driversList = action.payload;
        state.errors = {};
      })
      .addCase(fetchPartnerDriversList.rejected, (state, action) => {
        state.errors = action.payload;
        state.isLoading = false;
      })

      // Fetch partner driver info
      .addCase(fetchDriverInfo.pending, (state) => {
        state.isLoading = true;
        state.errors = {};
      })
      .addCase(fetchDriverInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.driverInfo = action.payload;
        state.errors = {};
      })
      .addCase(fetchDriverInfo.rejected, (state, action) => {
        state.errors = action.payload;
        state.isLoading = false;
      })

      // Fetch documents only for selec driver
      .addCase(fetchDocumentTitlesById.pending, (state) => {
        state.errors = {};
      })
      .addCase(fetchDocumentTitlesById.fulfilled, (state, action) => {
        state.documentPhotos = action.payload.documents;
        state.errors = {};
      })
      .addCase(fetchDocumentTitlesById.rejected, (state, action) => {
        state.errors = action.payload;
      });
  },
});

export const { actions: partnerActions } = partnerSlice;
export const { reducer: partnerReducer } = partnerSlice;
