const Typography = (theme) => {
  const baseFont14 = {
    fontSize: "14px",
    color: theme.palette.text.primary,
  };

  const baseFont16 = {
    fontSize: "16px",
    color: theme.palette.text.primary,
  };

  return {
    font12_light: {
      ...baseFont14,
      fontWeight: 500,
    },
    font14_light: {
      ...baseFont14,
      fontWeight: 400,
    },
    font14_medium: {
      ...baseFont14,
      fontWeight: 700,
    },
    font16_medium: {
      ...baseFont16,
      fontWeight: 500,
    },
    h1: {
      fontSize: "40px",
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  };
};

export default Typography;
