const url = process.env.REACT_APP_BACKEND_URL;
const urlMainApi = process.env.REACT_APP_MAIN_API;

export const mainBackendUrl = url;
export const mainBackendApi = urlMainApi;

export const apiLogin = `${url}/login`;
export const apiCreateDriver = `${urlMainApi}/partner/v1/drivers`;
export const apiDriver = `${urlMainApi}/partner/v1/drivers`;
export const apiFiles = `${urlMainApi}/file`;
export const apiDocuments = `${urlMainApi}/partner/v1/documents`;
export const apiAdmin = `${urlMainApi}/admin`;
export const apiCountry = `${url}/country`;
