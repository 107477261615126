import { memo } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import DriverRegistrationForm from "src/features/driver-registration-form";
import {
  getDriverActiveStep,
  getDriverData,
  getDriverDocuments,
  getDriverErrors,
  getDriverIsLoading,
} from "src/pages/create-driver/model/driverSelectors";
import { driverActions } from "src/pages/create-driver/model/driverSlice";
import { useNavigate } from "react-router-dom";

const CreateDriver = () => {
  const navigate = useNavigate();
  const errors = useSelector(getDriverErrors);
  const isLoading = useSelector(getDriverIsLoading);
  const activeStep = useSelector(getDriverActiveStep);
  const driverData = useSelector(getDriverData);
  const documentsData = useSelector(getDriverDocuments);

  const closeCreateDriver = () => {
    navigate("/partner");
  };

  return (
    <Grid container justifyContent="center" alignItems="center" height="100%">
      <DriverRegistrationForm
        documentsData={documentsData}
        isLoading={isLoading}
        errors={errors}
        driverActions={driverActions}
        activeStep={activeStep}
        driverData={driverData}
        onClose={closeCreateDriver}
      />
    </Grid>
  );
};

export default memo(CreateDriver);
