import { styled } from "@mui/material/styles";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 2,
  width: 16,
  height: 16,
  border: `1px solid ${theme.palette.primary.dark}`,
  backgroundImage: "linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0))",

  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: "2px",
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.commonColors.grey[500],
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.commonColors.grey[300],
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  border: "none",
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: "linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0))",

  "&::before": {
    display: "block",
    width: "16px",
    height: "16px",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },

  "input:hover ~ &": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export { BpIcon, BpCheckedIcon };
