import { useTranslation } from "react-i18next";

import { FormControl, FormGroup, Box, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { useField } from "formik";

import { addQueryParams } from "src/shared/lib/url/queryParamHelpers";

import { BpCheckedIcon, BpIcon } from "./styles";

const CheckboxGroupsField = ({ name, label, values }) => {
  const [field, _, helpers] = useField(name);

  const { t } = useTranslation();

  const isChecked = (option) => field?.value?.includes?.(option);

  const handleChange = (option) => {
    const newValue = isChecked(option) ? field.value.filter((value) => value !== option) : [...field.value, option];

    helpers.setValue(newValue);
    addQueryParams({ [name]: newValue });
  };

  return (
    <FormControl sx={{ display: "flex", flexDirection: "column", mb: "30px" }}>
      <Typography component="p" gutterBottom variant="font14_light">
        {label}
      </Typography>
      <FormGroup sx={{ ml: 2 }}>
        {Object.entries(values).map(([text, count]) => {
          const isDisabled = count === 0;

          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked(text)}
                  checkedIcon={<BpCheckedIcon />}
                  color="secondary"
                  disabled={isDisabled}
                  icon={<BpIcon />}
                  onChange={() => handleChange(text)}
                  sx={{
                    "&:hover": { bgcolor: "transparent" },
                  }}
                  value={text}
                />
              }
              key={text}
              label={
                <Box>
                  <Typography color={isDisabled && "text.placeholder"} sx={{ mr: 3 }} variant="font14_light">
                    {t(text)}
                  </Typography>
                  <Typography color={isDisabled ? "text.placeholder" : "text.secondary"} variant="font14_light">
                    ({count})
                  </Typography>
                </Box>
              }
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroupsField;
