const Button = () => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          letterSpacing: "0.3px",
        },
        contained: {
          borderRadius: 6,
        },
        outlined: {
          borderRadius: 8,
          borderWidth: 1,
          padding: "12px 20px 12px 16px",
          borderColor: "currentColor",

          "&:hover": {
            borderWidth: 1,
          },
        },
        endIcon: {
          marginLeft: 7,
        },
        sizeSmall: {
          padding: 0,
          minWidth: 48,
          minHeight: 30,
          borderRadius: 4,
        },
        sizeLarge: {
          padding: "12px 22px",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  };
};

export default Button;
