import lightCommonColors from "./colors/light-theme-colors/common-colors";

const palette = (mode) => {
  const paletters = {
    light: {
      ...lightCommonColors,
    },
  };

  return {
    ...paletters[mode],
  };
};
export default palette;
