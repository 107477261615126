const lightCommonColors = {
  commonColors: {
    grey: {
      50: "#F8F9FA",
      100: "#CFD8DC",
      200: "#ADB5BD",
      300: "#E9ECEF",
      500: "#ebf1f5",
    },
  },
  primary: {
    main: "#DEE2E6",
    light: "#FFFFFF",
    dark: "#ABB5BE",
  },
  secondary: {
    main: "#3D8BFD",
    light: "#2196f3",
  },
  active: {
    main: "#343A40",
    primary: "#242424",
  },
  icon: {
    main: "#343A40",
    secondary: "#6C757D",
  },
  text: {
    main: "#343A40",
    secondary: "#6C757D",
    light: "#FFFFFF",
    placeholder: "#ADB5BD",
  },
  background: {
    main: "#FFFFFF",
    primary: "#F8F9FA",
  },
  // grey: {
  //   50: "#F8F9FA",
  //   100: "#CFD8DC",
  //   200: "#ADB5BD",
  //   300: "#E9ECEF",
  //   500: "#ebf1f5",
  // },
};

export default lightCommonColors;
