import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";

import { changeLanguage } from "i18next";
import PartnerLayout from "src/layouts/PartnerLayout";

import Footer from "./components/common-components/footer";
import Layout from "./components/common-components/layout";
import Loader from "./components/common-components/loader";
import { LoaderContext } from "./context";
import { changeTheme } from "./helper/changeTheme";
import RequireAuth from "./hoc/RequireAuth";
import About from "./pages/about";
import CreateDriver from "./pages/create-driver";
import { driverActions } from "./pages/create-driver/model/driverSlice";
import Driver from "./pages/driver";
import Hero from "./pages/hero";
import Inspection from "./pages/inspection";
import { getPartnerTokens } from "./pages/partner/model/partnerSelectors";
import { partnerActions } from "./pages/partner/model/partnerSlice";
import DriverInvoice from "./pages/partner/pages/driver-invoice";
import DriverSalary from "./pages/partner/pages/driver-salary";
import DriversPage from "./pages/partner/pages/drivers-page";
import PartnerAuth from "./pages/partner/pages/partner-auth";
import PartnerHomePage from "./pages/partner/pages/partner-home";
import PartnerInvoice from "./pages/partner/pages/partner-invoice";
import Passenger from "./pages/passenger";
import Profile from "./pages/profile";
import Tariffs from "./pages/tariffs";
import { getCountry } from "./shared/api/language";

import "./styles/index.scss";

function App() {
  const [isLoading, setLoading] = useState(true);

  const partnerTokens = useSelector(getPartnerTokens);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const handleExit = () => {
    dispatch(partnerActions.logout());
    navigate("/partner");
  };

  const isPartnerPage = () => {
    const routes = ["/partner/create-driver", "/partner", "/partner/inspection", "/partner/profile"];

    return routes.some((routeItem) => location.pathname.includes(routeItem));
  };

  const changePage = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    window.scrollTo(0, 0);
  };

  const value = {
    changePage,
  };

  useEffect(() => {
    const fetchData = () => {
      getCountry(changeLanguage, changeTheme, setLoading);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <LoaderContext.Provider value={value}>
      {!isPartnerPage() && <Layout />}

      <Routes>
        <Route element={<Hero />} index />
        <Route element={<Passenger />} path="/passenger" />
        <Route element={<Driver />} path="/driver" />
        <Route element={<Tariffs />} path="/tariffs" />
        <Route element={<About />} path="/about" />
        <Route element={<PartnerLayout />} path="/partner">
          <Route
            element={
              <RequireAuth>
                <PartnerHomePage />
              </RequireAuth>
            }
            index
          />
          <Route element={<PartnerAuth />} path="auth" />
          <Route
            element={
              <RequireAuth>
                <PartnerInvoice />
              </RequireAuth>
            }
            path="partner-invoice"
          />
          <Route
            element={
              <RequireAuth>
                <DriversPage />
              </RequireAuth>
            }
            path="drivers"
          />
          <Route
            element={
              <RequireAuth>
                <CreateDriver />
              </RequireAuth>
            }
            path="drivers/create-driver"
          />
          <Route
            element={
              <RequireAuth>
                <Inspection />
              </RequireAuth>
            }
            path="drivers/inspection/:inspectionId"
          />
          <Route
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
            path="drivers/:driverId"
          />
          <Route
            element={
              <RequireAuth>
                <DriverSalary />
              </RequireAuth>
            }
            path="drivers/salary/:driverId"
          />
          <Route
            element={
              <RequireAuth>
                <DriverInvoice />
              </RequireAuth>
            }
            path="drivers/invoice/:driverId"
          />
        </Route>
      </Routes>

      {!isPartnerPage() && <Footer />}
    </LoaderContext.Provider>
  );
}

export default App;
