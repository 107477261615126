const Table = (theme) => {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.main,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root": {
            transition: "background-color 0.2s ease",
            "&:nth-of-type(odd)": {
              backgroundColor: theme.palette.background.primary,
              border: 0,

              "&:hover": {
                background: theme.palette.commonColors.grey[300],
              },
            },
          },

          "&:last-child td, &:last-child th": {
            border: 0,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${theme.palette.primary.main}`,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
      },
    },
  };
};

export default Table;
