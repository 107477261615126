import { rtkApi } from "src/shared/api/rtkApi";
import { mainBackendApi } from "src/shared/config";

export const driverInvoiceQuery = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceData: builder.query({
      query: ({ driverId, take, skip }) => ({
        url: `${mainBackendApi}/partner/v1/drivers/${driverId}/invoices?skip=${skip}&take=${take}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetInvoiceDataQuery } = driverInvoiceQuery;