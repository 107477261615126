import React, { Fragment } from "react";

import { Box, Table, TableBody, TableCell, TableHead, TableRow, Skeleton } from "@mui/material";

export const SkeletonTable = () => {
  const renderSkeletonRow = () => (
    <TableRow hover>
      <TableCell>
        <Skeleton height={56} variant="circular" width={56} />
      </TableCell>
      {Array.from({ length: 11 }).map((_, index) => (
        <TableCell key={index}>
          <Skeleton variant="text" width="80%" />
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <Box sx={{ paddingBottom: "20px" }}>
      <Table>
        <TableHead>
          <TableRow>
            {Array.from({ length: 12 }).map((_, index) => (
              <TableCell key={index} sx={{ lineHeight: "17px", padding: "35px 16px" }}>
                <Skeleton variant="text" width={index === 0 ? "13%" : "80%"} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: 5 }).map((_, index) => (
            <Fragment key={index}>{renderSkeletonRow()}</Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
