const BOOLEAN_ARRAY_LENGTH = 2;

//* If the name of the keys that the server gives to filter does not match the one that should be given back to you *//
const keyMappings = {
  activationStatus: "active",
};

//* From [70 - 80] to object {from: 70, to: 80} *//
const parseRange = (range) => {
  const [from, to] = range.split(" - ").map(Number);
  return { from, to };
};

const isRangeValue = (value) => "from" in value && "to" in value && value.from !== null && value.to !== null;

const transformArrayValues = (value, schemaValues) => {
  const schemaKeys = Object.keys(schemaValues);

  if (schemaKeys.length !== BOOLEAN_ARRAY_LENGTH && value.length !== schemaKeys.length) {
    return value.map(parseRange);
  } else if (value.length !== schemaKeys.length) {
    return value.includes(schemaKeys[0]);
  }
};

const transformValue = (key, value, initialSchema) => {
  const schemaValues = initialSchema[key]?.values || {};

  if (Array.isArray(value) && value.length > 0) {
    return transformArrayValues(value, schemaValues);
  } else if (isRangeValue(value)) {
    return value;
  }

  return null;
};

const transformValuesToServer = (formData, initialSchema) => {
  return Object.entries(formData).reduce((transformedValues, [key, value]) => {
    const transformedValue = transformValue(key, value, initialSchema);

    if (transformedValue !== null) {
      const mappedKey = keyMappings[key] || key;
      transformedValues[mappedKey] = transformedValue;
    }

    return transformedValues;
  }, {});
};

export default transformValuesToServer;
