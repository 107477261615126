import { Navigate } from "react-router-dom";

import useAuth from "src/shared/hooks/useAuth";

const RequireAuth = ({ children }) => {
  const { isAuth } = useAuth();

  if (!isAuth) {
    return <Navigate replace={true} to="/partner/auth" />;
  }

  return children;
};

export default RequireAuth;
