import { t } from "i18next";
import React from "react";

import styles from "./styles.module.scss";
import { defaultCashCommission, salaryAmountCategories } from "../../constants";
import { convertCoinsToMoney } from "../../helpers/convertCoinsToMoney";

const SalaryDetails = ({ currency, currentPayment, salaryData }) => {
  const { earnAmountByCategories, totalAmountInCoins, expensesAmountByCategories } = salaryData;

  // get total income money by category
  const amountByCategory = () => {
    switch (currentPayment.value) {
      case salaryAmountCategories.cash:
        return earnAmountByCategories.cash.total;
      case salaryAmountCategories.inApp:
        return (
          earnAmountByCategories.inApp.total +
          earnAmountByCategories.compensations.total +
          earnAmountByCategories.declineFee.total +
          earnAmountByCategories.tips.total
        );
      default:
        return (
          earnAmountByCategories.cash.total +
          earnAmountByCategories.inApp.total +
          earnAmountByCategories.compensations.total +
          earnAmountByCategories.declineFee.total +
          earnAmountByCategories.tips.total
        );
    }
  };

  // get total fee by category
  const getFeeByCategory = () => {
    switch (currentPayment.value) {
      case salaryAmountCategories.cash:
        return defaultCashCommission;
      case salaryAmountCategories.inApp:
      default:
        return expensesAmountByCategories.fee.total;
    }
  };

  const detailsItems = [
    {
      title: `${t("earn")}`,
      value: `${convertCoinsToMoney({ coins: amountByCategory() })}`,
      subItems: () => {
        switch (currentPayment.value) {
          case salaryAmountCategories.cash:
            return [
              {
                title: `${t("cash")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.cash.total })}`,
              },
            ];
          case salaryAmountCategories.inApp:
            return [
              {
                title: `${t("in_app")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.inApp.total })}`,
              },
              {
                title: `${t("compensation")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.compensations.total })}`,
              },
              {
                title: `${t("cancellation_fee")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.declineFee.total })}`,
              },
              {
                title: `${t("tips")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.tips.total })}`,
              },
            ];
          default:
            return [
              {
                title: `${t("in_app")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.inApp.total })}`,
              },
              {
                title: `${t("cash")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.cash.total })}`,
              },
              {
                title: `${t("compensation")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.compensations.total })}`,
              },
              {
                title: `${t("cancellation_fee")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.declineFee.total })}`,
              },
              {
                title: `${t("tips")}`,
                value: `${convertCoinsToMoney({ coins: earnAmountByCategories.tips.total })}`,
              },
            ];
        }
      },
    },
    {
      title: `${t("expenses")}`,
      value: `${convertCoinsToMoney({ coins: getFeeByCategory() })}`,
      subItems: () => {
        switch (currentPayment.value) {
          default:
            return [
              {
                title: `${t("commission")}`,
                value: `${convertCoinsToMoney({ coins: getFeeByCategory() })}`,
              },
            ];
        }
      },
    },
    {
      title: `${t("net_income")}`,
      value: `${convertCoinsToMoney({ coins: Number(amountByCategory()) - Number(getFeeByCategory()) })}`,
    },
  ];

  return (
    <div className={styles.detailsItemsWrap}>
      {detailsItems.map((item, index) => (
        <li className={styles.detailsItem} key={`${item.title}_${index}`}>
          <div className={styles.detailItemTitle}>
            <p>{item.title}</p>
            <p>
              {item.value} {currency}
            </p>
          </div>
          <div className={styles.subItemsWrap}>
            {typeof item.subItems === "function" &&
              item.subItems().map((subItem, index) => (
                <li className={styles.subItem} key={`${subItem.title}_${index}`}>
                  <p>{subItem.title}</p>
                  <p>
                    {subItem.value} {currency}
                  </p>
                </li>
              ))}
          </div>
        </li>
      ))}
    </div>
  );
};

export default SalaryDetails;
