const Typography = () => {
  return {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: "9px",
        },
      },
    },
  };
};

export default Typography;
