const Input = (theme) => {
  return {
    MuiInput: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiTextField: {
      padding: "0 14px",
      styleOverrides: {
        root: {
          "&.searchTextField": {
            "& fieldset": {
              border: "none",
            },
            "& .MuiInputBase-root": {
              backgroundColor: theme.palette.background.primary,
              borderRadius: "8px",
              "&::placeholder": {
                color: theme.palette.text.light,
              },
            },
            "& .MuiInputBase-input": {
              fontWeight: 400,
              fontSize: "21px",
              "&::placeholder": {
                color: theme.palette.text.placeholder,
                letterSpacing: "0.25px",
                opacity: 1,
              },
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        sizeSmall: {
          "& .MuiOutlinedInput-input": {
            padding: "4px 10px",
            fontSize: "14px",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&:not(.Mui-checked)": {
            color: theme.palette.primary.disabled, // Цвет невыбранного чекбокса
            "& .MuiSvgIcon-root": {
              // Применение стилей к SVG иконке
            },
          },
        },
      },
    },
  };
};

export default Input;
