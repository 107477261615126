import React from "react";

import { CircularProgress, Box } from "@mui/material";

const CircularLoader = ({ color }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80vh" }}>
      <CircularProgress color={color} sx={{ height: "100%" }} />
    </Box>
  );
};

export default CircularLoader;
