import CheckIcon from "@mui/icons-material/Check";
import HttpsIcon from "@mui/icons-material/Https";

const getStatusIcon = (row) => {
  if (row.isBlocked || row.isInBlacklist) {
    return <HttpsIcon fontSize="small" />;
  }

  return <CheckIcon color="icon" fontSize="small" />;
};

export default getStatusIcon;
