import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";
import { t } from "i18next";

import SalaryModule from "src/features/salary-module";
import { dateToCorrectFormat } from "src/features/salary-module/helpers/dayToCorrectFormat";

import ContentWrap from "src/shared/components/content-wrap";
import CircularLoader from "src/shared/components/loaders/circle-loader";
import PathBreadcrumbs from "src/shared/components/path-breadcrumbs";
import { currency } from "src/shared/constants/currency";

import { useGetDriverSalaryDataQuery } from "./store/queries/salary-query";
import { fetchDriverInfo } from "../../model/partnerAsyncActions";
import { getDriverInfo } from "../../model/partnerSelectors";

const DriverSalary = () => {
  const { driverId } = useParams();
  const dispatch = useDispatch();
  const driverInfo = useSelector(getDriverInfo);

  const [salaryDateFrom, setSalaryDateFrom] = useState(dateToCorrectFormat(dayjs()));
  const [salaryDateTo, setSalaryDateTo] = useState(dateToCorrectFormat(dayjs()));

  useEffect(() => {
    const fetchDriver = async () => {
      await dispatch(fetchDriverInfo(driverId));
    };

    fetchDriver();
  }, [dispatch, driverId]);

  const { data, error, isLoading } = useGetDriverSalaryDataQuery({
    driverId,
    from: salaryDateFrom,
    to: salaryDateTo,
  });

  const isLoaded = driverInfo && !isLoading && data;

  const links = [
    { url: "/partner", text: t("main_page") },
    { url: "/partner/drivers", text: t("drivers") },
    { text: t("payroll_module") },
  ];

  return (
    <>
      <PathBreadcrumbs links={links} sx={{ margin: "28px 0" }} />

      {!isLoaded ? (
        <CircularLoader />
      ) : (
        <SalaryModule
          currency={currency[driverInfo.currency]}
          dateFrom={salaryDateFrom}
          dateTo={salaryDateTo}
          salaryData={data}
          setDateFrom={(date) => setSalaryDateFrom(date)}
          setDateTo={(date) => setSalaryDateTo(date)}
        />
      )}
    </>
  );
};

export default memo(DriverSalary);
