import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { Grid } from "@mui/material";

import AuthPartner from "src/features/auth-partner";

import useAuth from "src/shared/hooks/useAuth";

import { getPartnerErrors, getPartnerIsLoading } from "../../model/partnerSelectors";

const PartnerAuth = () => {
  const isLoading = useSelector(getPartnerIsLoading);
  const errors = useSelector(getPartnerErrors);

  const { isAuth } = useAuth();

  if (isAuth) {
    return <Navigate to="/partner/drivers" />;
  }

  return (
    <Grid alignItems="center" container height="100%" justifyContent="center" mt={5}>
      <AuthPartner errors={errors} isLoading={isLoading} />;
    </Grid>
  );
};

export default PartnerAuth;
