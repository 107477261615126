import { t } from "i18next";

function replaceFirstUpperCaseWithLowerCase(word) {
  return word.charAt(0).toLowerCase() + word.slice(1);
}

const getTitleForTags = (name) => {
  switch (name) {
    case "balance":
      return replaceFirstUpperCaseWithLowerCase(t("balance"));
    case "carmaCommission":
      return replaceFirstUpperCaseWithLowerCase(t("carmaCommission"));
    case "rating":
      return replaceFirstUpperCaseWithLowerCase(t("rating"));

    default:
      return "";
  }
};

export default getTitleForTags;
