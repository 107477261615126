import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";

import DropdownList from "src/shared/components/DropdownList";

const ActionsDropdown = (props) => {
  const { dropdownAnchor, onCloseDropdown, options, actions } = props;

  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <DropdownList anchorEl={dropdownAnchor} onClose={onCloseDropdown} options={options}>
      {actions.map((option) => (
        <Box
          key={option.text}
          onClick={option.onClick}
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "20px 16px 16px 16px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            gap: "12px",
            "& svg path": {
              fill: palette.icon.secondary,
            },
            "&:hover": {
              "& svg path": {
                fill: palette.icon.main,
              },
              "& .MuiTypography-root": {
                color: palette.text.main,
              },
            },
          }}
        >
          {option?.icon}
          <Typography color="text.secondary" variant="font12_light">
            {t(option.text)}
          </Typography>
        </Box>
      ))}
    </DropdownList>
  );
};

export default ActionsDropdown;
